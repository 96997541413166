.Login__Page {
    margin-top: -60px;
    width: 100vw;
    height: 100vh;
    padding-top: 80px;
    background: #2ad2c9; /* fallback for old browsers */
    background: -webkit-linear-gradient(right, #2ad2c9, #cde2c1);
    background: -moz-linear-gradient(right, #2ad2c9, #cde2c1);
    background: -o-linear-gradient(right, #2ad2c9, #cde2c1);
    background: linear-gradient(to left, #2ad2c9, #cde2c1);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .Login__Title {
        text-align: center;
        margin-bottom: 20px;
    }
    .Login__Page {
        width: 360px;
        padding: 8% 0 0;
        margin: auto;
    }
    .Login__Form {
        position: relative;
        z-index: 1;
        background: #ffffff;
        max-width: 360px;
        margin: 0 auto 100px;
        padding: 45px;
        text-align: center;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2),
            0 5px 5px 0 rgba(0, 0, 0, 0.24);
    }
    .Login__Form input {
        outline: 0;
        background: #f2f2f2;
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
    }
    .Login__Form .Login__Submit {
        outline: 0;
        background: #2ad2c9;
        width: 100%;
        border: 0;
        padding: 15px;
        color: #ffffff;
        font-size: 14px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;
    }
    .Login__Form .Login__Submit:hover,
    .Login__Form .Login__Submit:active,
    .Login__Form .Login__Submit:focus {
        background: #2ad2c9;
    }
    .Login__Form .message {
        margin: 15px 0 0;
        color: #b3b3b3;
        font-size: 12px;
    }
    .Login__Form .message a {
        color: #2ad2c9;
        text-decoration: none;
    }
    .Login__Form .register-Login__Form {
        display: none;
    }

    .Login__Warn {
        color: #d4371b;
        font-size: 12px;
    }
}

.Home {
    margin-top: 20px;
    width: 1300px;
    height: 2900px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    .container {
        width: 90%;
        height: 90%;
        display: grid;
        grid-template-areas:
            "head       head"
            "nav        nav"
            "total      signup"
            "classInfo  payment"
            "freeTrial  paymentInfo"
            "upcoming   upcoming"
            "complete   complete";
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 50px 50px 80px 400px repeat(3, 1fr);
        grid-gap: 32px;

        white-space: pre-line;
    }
}

.Words {
    height: 100%;
    width: 100%;

    .test1 {
        color: red;
    }

    .Words_wrap {
        display: flex;
        align-items: center;
    }

    .Words__Title {
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .Words-editBtn {
        width: fit-content;
        background-color: #2ad2c9;
        font-weight: bold;
        color: white;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
    }

    .Words-editBtn:hover {
        opacity: 0.5;
    }

    .Words-deleteBtn {
        margin-top: 10px;
        background-color: red;
        width: fit-content;
        font-weight: bold;
        color: white;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
    }

    .Words-deleteBtn:hover {
        opacity: 0.5;
    }

    .Words-textEng {
        font-weight: bold;
    }

    .Words_Meaning {
        .Meaning {
            padding: 5px;
            border-radius: 5px;

            display: flex;
            flex-direction: column;

            .Meaning_wrap {
                padding: 5px;
                border-radius: 5px;
                .Meaning_wp-text {
                    font-weight: bold;
                    padding: 3px;
                    border-radius: 4px;
                }

                .Meaning_wp-wrongs {
                    display: flex;

                    .Meaning_wp-wrongs-wrongTag {
                        margin-right: 5px;
                        padding: 3px 5px;
                        border-radius: 10px;
                        background-color: darkgray;
                        color: white;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }

            .ExampleSentence {
                padding: 5px;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                margin-left: 30px;
                font-weight: bold;

                .ExampleSentence-eng {
                    width: 100%;
                    white-space: pre-wrap;
                }

                .ExampleSentence_wrongs {
                    display: flex;

                    .ExampleSentence_wr-wrongTag {
                        margin-right: 5px;
                        padding: 3px 5px;
                        border-radius: 10px;
                        background-color: darkgray;
                        color: white;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }

            .Meaning_wrap:hover,
            .ExampleSentence:hover {
                background-color: #2ad2c9;
                color: white;
                cursor: pointer;
            }
        }
    }
}

.exbox-preview {
    width: fit-content;
    cursor: help;
    background-color: #2ad2c9;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    padding: 5px 3px;
    border-radius: 5px;
}

.exbox-previewImage {
    opacity: 1;
    width: 100%;
}

.WordDialog_audio {
    // border: 1px solid red;
    margin-top: 10px;
    display: flex;
    align-items: center;

    .WordDialog_ad-editBtn {
        padding: 3px 5px;
        cursor: pointer;
        border: 2px dashed gray;
        color: #fff;
        background-color: #aaa;
        border-radius: 6px;
    }

    .WordDialog_ad-editBtn:hover {
        opacity: 0.5;
    }

    .WordDialog_ad-fileName {
        margin-left: 10px;
    }

    .WordDialog_ad-fileName:hover {
        cursor: pointer;
        background-color: #aaa;
    }
}

.Users {
    height: 100%;
    width: 100%;

    .Users_Wrap {
        display: flex;
        align-items: center;
    }

    .Users__Title {
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .Users_Input {
        width: 100%;
        outline: none;
        padding: 0 3px;
    }

    .User-deleteBtn {
        color: red;
        cursor: pointer;
    }
}

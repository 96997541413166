.Cell_center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Cell-deleteBtn {
    color: white;
    cursor: pointer;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 70px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 15px;
}

.Cell-deleteBtn:hover {
    opacity: 0.5;
}

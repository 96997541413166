.SerialNum {
    height: 100%;
    width: 100%;

    .SerialNum_wrap {
        display: flex;
        align-items: center;
    }

    .SerialNum__Title {
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .SerialNum-editBtn {
        background-color: #2ad2c9;
        font-weight: bold;
        color: white;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
    }

    .SerialNum-editBtn:hover {
        opacity: 0.5;
    }

    .SerialNum-textEng {
        font-weight: bold;
    }

    .SerialNum-deleteBtn {
        background-color: red;
        width: fit-content;
        font-weight: bold;
        color: white;
        padding: 5px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
    }
    .SerialNum-deleteBtn:hover {
        opacity: 0.5;
    }
}

.Inquiry {
    height: 100%;
    width: 100%;

    .Inquiry_wrap {
        display: flex;
        align-items: center;
    }

    .Inquiry__Title {
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .Inquiry-question,
    .Inquiry-answer {
        white-space: pre-wrap;
    }

    .Inquiry-notAnswer {
        color: red;
    }

    .Inquiry-answerbtn {
        // padding: 1px 3px;
        width: 100px;
        background-color: #2ad2c9;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        height: 30px;
        border-radius: 15px;
        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
    }
    .Inquiry-answerbtn:hover {
        opacity: 0.5;
    }

    .Inquiry-textarea {
        width: 100%;
    }

    .Inquiry-editBtn {
        background-color: #2ad2c9;
        font-weight: bold;
        color: white;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
    }

    .Inquiry-editBtn:hover {
        opacity: 0.5;
    }

    .Inquiry-textEng {
        font-weight: bold;
    }
}

.AddNotice {
    margin-top: 20px;
    .AddNotice-title {
        font-size: 30px;
        font-weight: bold;
    }

    .AddNotice-subtitle {
        font-size: 20px;
        font-weight: bold;
        color: red;
    }

    .AddNotice_inputs {
        width: 500px;
        margin-top: 30px;
        border: 2px solid gray;
        border-radius: 20px;
        padding: 20px;

        .AddNotice_ips_wrap {
            display: flex;
            margin-bottom: 20px;
        }

        .AddNotice_ips-title {
            font-weight: bold;
            width: 80px;
        }

        .AddNotice_ips-input {
            width: 100%;

            outline: none;
        }
    }

    .AddNotice_addBtn {
        margin-top: 10px;
        background-color: #2ad2c9;
        width: 150px;
        height: 40px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        font-weight: bold;
        font-size: 20px;
        opacity: 0.5;
        pointer-events: none;
    }

    .AddNotice_addBtn-active {
        opacity: 1;
        pointer-events: auto;
        cursor: pointer;
    }
}

.Menu {
    width: 270px;
    min-width: 270px;

    .Menu__Title {
        position: fixed;
        left: 0;
        top: -20px;
        line-height: 50px;
        width: 100%;
        padding-left: 20px;
        background: #2ad2c9;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2),
            0 5px 5px 0 rgba(0, 0, 0, 0.1);
        color: #ffffff;
        text-decoration: none;
        z-index: 100;
    }

    .Menu__Footer {
        white-space: pre-line;
        position: fixed;
        padding: 10px;
        left: 0;
        bottom: 0px;
        line-height: 30px;
        width: 100%;
        padding-left: 20px;
        background: #2ad2c9;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2),
            0 5px 5px 0 rgba(0, 0, 0, 0.1);
        color: #ffffff;
        text-decoration: none;
        z-index: 100;
        font-weight: bold;
    }
    .Menu__Items {
        list-style: none;
        margin: 20px 10px;
        padding: 10px;

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);

        .Menu__Items__Title {
            color: #888888;
            cursor: default;
            hr {
                margin: 10px 0px;
            }
        }

        .Menu__Item {
            position: relative;
            cursor: pointer;
            line-height: 45px;
            text-decoration: none;
            color: #888888;
        }

        .Menu__Active {
            color: #2ad2c9;
            font-weight: bold;
        }

        i {
            width: 23px;
            text-align: center;
        }

        span {
            position: absolute;
            left: 32px;
        }
    }
}

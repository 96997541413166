.Instashare {
    height: 100%;
    width: 100%;

    .Instashare_wrap {
        display: flex;
        align-items: center;
    }

    .Instashare__Title {
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .Instashare-editBtn {
        background-color: #2ad2c9;
        font-weight: bold;
        color: white;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 15px;
        cursor: pointer;
    }

    .Instashare-editBtn:hover {
        opacity: 0.5;
    }

    .Instashare-textEng {
        font-weight: bold;
    }
}
